
  
  .vehicle-info{
    text-align: center;
  }
  
  h1 {
    color: #333;
  }

  h2{
    margin: 30px
  }
  
  table {
    border-collapse: collapse;
    width: 60%;
    margin: 20px auto;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  thead {
    background-color: #ffa43e;;
    color: #fff;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  tbody {
    transition: max-height 1s ease-in-out;
    overflow: hidden;
  }
  tbody tr:hover {
    background-color: #f5f5f5;
  }
  tbody:not(.expanded) {
    max-height: 150px; /* Adjust this value based on your table content */
  }
  
  .inside-header{
    display: flex;
    justify-content: space-between;
    height: auto;
  }
 
  /* buttons CSS */
  #button-show {
    align-items: center;
    background-color: initial;
    background-image: linear-gradient(#464d55, #25292e);
    border-radius: 8px;
    border-width: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 18px;
    height: 52px;
    justify-content: center;
    line-height: 1;
    margin: 0;
    outline: none;
    overflow: hidden;
    padding: 0 32px;
    text-align: center;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    transition: all 150ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-show:hover {
    box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
    opacity: .85;
  }
  
  .button-show:active {
    outline: 0;
  }
  
  .button-show:focus {
    box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
  }
  
  @media screen and (max-width:799px) {
    .button-price{
      display: none;
    }
    
  }
  
  @media (max-width: 420px) {
    .button-show {
      height: 48px;
    }
  }
  


/* CSS */
.button-price {
  
  margin-top: 30px;
  height: 40px;
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-price:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-price 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-price {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-price:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}


.body {
    max-height: 80vh;
    overflow: auto;
    background-color: #eee;
    border-radius: 3px;
  }
  
  .bigBody {
    composes: body;
    max-height: 80vh;
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.4) inset;
    border: 1px solid #ddd;
  }
  
  .row {
    padding: 20px;
    display: flex;
    align-items: flex-start;
  }
  
  .rowAlignEnd {
    padding: 20px;
    display: flex;
    align-items: flex-end;
  }
  
  .selfEnd {
    align-self: flex-end;
  }
  
  .content {
    background-color: #fff;
    padding: 10px;
    flex: auto;
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.4);
    height: 500px;
    margin: 0 10px;
  }
  
  .flexibleHeightContent {
    composes: content;
    height: initial;
  }
  
  .smallContent {
    composes: content;
    height: 100px;
  }
  
  .miniContent {
    composes: content;
    height: 40px;
  }
  
  .largeContent {
    composes: content;
    min-height: 700px;
    height: 120vh;
  }
  
  .sidebar {
    background-color: #fff;
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.4);
    padding: 10px;
    min-width: 120px;
    margin: 0 10px;
  }
  
  .fauxNavElement {
    height: 10px;
    background-color: #eee;
  }
  
  .fauxNav {
    margin: 20px 0;
  }
  
  .fauxNavElement:not(:last-child) {
    margin-bottom: 10px;
  }
  
  
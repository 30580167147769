/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */   
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}
#myNav{
  width: 100%
}
/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
.widthHun{
  width: 100% !important;
}
.widthZero{
  width: 0%;
}
.navbar-item {  
  vertical-align: middle !important;
}
.dropdown-item:hover{
  background: var(--darkOrange) !important;
  color: #ffffff;
}
.nav-background {
  background: var(--darkOrange) !important;
  height: auto !important;
}
.activeNav {
  border-bottom: 3px #ffffff solid;
  font-weight: bold;
  text-decoration: none !important;
}
.navbar-item:hover{
  color: var(--brown) !important;
}
.activeLastNav{
  color: var(--darkOrange) !important;
}
.icon-size {
  font-size: 20px;
}
.navbar-last-item{
  vertical-align: middle !important;
  border-radius: 25px;
}
.navbar-last-item:hover{
  color: var(--darkOrange) !important;
}
@media only screen and (max-width: 768px) {
  .navbar-item {
    border-left: none;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 0.5px solid #e5e5e5;
  }
}
.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}
button:focus {
  outline: none !important;
}
.navbar-toggler {
  padding: 0 !important;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: none !important;  
}
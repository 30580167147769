.carousel-image .card-body {
  background: #ffecd3 !important;
  padding: 20px 20px !important;
}
.card {
  border: none !important;
}
.carousel-custom {
  background: #ffecd3 !important;  
}
.book-now-button{
    background: #FFF2E0;
}
.call-now-button {
  background: url("../../images/button-bg.png");
  background-size: cover;
  background-position: center;  
}
.bg-home {
  background: url("../../images/background.png");
  background-size: contain;
  background-position: center;  
}
.table-headings {
  background: var(--darkOrange);
}
.center-aligned {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
}
.center-aligned-home {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

/* Blogs */
#news{
  background: #ffecd3;
}
.card-news{
  box-shadow: 5px 5px 20px rgba(0,0,0,0.15);
}
.mutual-heading{    
  border-bottom: 3px solid var(--darkOrange);
  width: 15%;
}
.carousel-alignment{
  text-align: left !important;
}


/* General styles for sections */
section {
  padding: 20px;
  margin: 20px 0;
  background-color: #ffecd3; /* Matching the existing background color */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Headings within sections */
section h1, section h2 {
  color: #333; /* Matching the color from package.css */
  margin-bottom: 15px;
}

/* Paragraphs within sections */
section p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Specific styles for the first section */
section:first-of-type {
  background-color: #ffffff;
}

@media only screen and (max-width: 993px){
  .bg-home {
      background-image: linear-gradient(
          to bottom,
          transparent,
          transparent,
          transparent,
          transparent,
          transparent,
          #fff9f2,
          #fff8ef,
          #fff7ef,
          #f7e2c7,
          #f7d7ad,
          #ffd298,
          #ffcd8c
        );        
  }
  .carousel-alignment{
    text-align: center !important;
  }
  .carousel-image .card-body {
    background: #ffecd3 !important;
    padding: 5px !important;
  }
}
.blogs-heading{
  position: absolute;
  bottom: 4%;
  left: 4%;
}
.blogs-img{
  position: relative;
}
/* .sticky-top{
  position: -webkit-sticky;
  position: sticky !important;
  top: 100 !important;  
  z-index: 2000;
} */
